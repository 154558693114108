import { ACCEPTED_TRIGGERS, EVENTS } from './constants';

/*
 * Sets aria attributes and adds eventListener on each toggle button
 * 
 * @param Store, Object, model or state of the current instance
 */
export const initUI = Store => () => {
    const { toggles, node, settings } = Store.getState();
    toggles.forEach(toggle => {
        const id = node.getAttribute('id');
        if (toggle.tagName !== 'BUTTON') toggle.setAttribute('role', 'button');
        if (!id) throw console.warn(`The toggle target should have an id.`);
        toggle.setAttribute('aria-controls', id);
        toggle.setAttribute('aria-expanded', 'false');

        toggle.addEventListener('click', e => {
            e.preventDefault();
            startToggleLifecycle(Store)();
        });
    });
};

/*
 * Dispatches a toggle action to the Store
 * 
 * @param Store, Object, model or state of the current instance
 */
export const toggle = Store => () => {
    Store.dispatch({
        isOpen: !Store.getState().isOpen },
    [toggleAttributes, broadcast(Store)]
    );
};

/*
 * Partially applied function that returns a function that begins the toggle lifecycle (prehook > toggle > callback)
 * 
 * @param Store, Object, model or state of the current instance
 * @returns Function
 */
export const startToggleLifecycle = Store => () => {
    const { node, toggles, settings, isOpen } = Store.getState();
    (settings.prehook && typeof settings.prehook === 'function') && settings.prehook({ node, toggles, isOpen });
    toggle(Store)();
    (!!settings.callback && typeof settings.callback === 'function') && settings.callback({ node, toggles, isOpen: Store.getState().isOpen });
};

/*
 * Returns an Array of HTMLElements selected based on data-toggle attribute of a given node
 * 
 * @param node, HTMLElement, node to be toggled
 * @return Array of HTMLElements
 */
export const findToggles = node => {
    const toggleSelector = node.getAttribute('data-toggle');
    const composeSelector = classSelector => { return ACCEPTED_TRIGGERS.map(sel => `${sel}.${classSelector}`).join(", "); }

    const toggles = node.getAttribute('data-toggle') && [].slice.call(document.querySelectorAll(composeSelector(toggleSelector)));

    if (!toggles) console.warn(`Toggle cannot be initialised, no buttons or anchors found for ${node}. Does it have a data-toggle attribute that identifies toggle buttons?`);
    return toggles;
};

/*
 * Change toggle button attributes and node target classNames
 * 
 * @param props, Object, composed of properties of current state required to accessibly change node toggles attributes
 */
export const toggleAttributes = ({ toggles, isOpen, classTarget, statusClass }) => {
    toggles.forEach(toggle => toggle.setAttribute('aria-expanded', isOpen));
    classTarget.classList[isOpen ? 'add' : 'remove'](statusClass);
};

/*
 * Partially applied function that returns a handler function for keydown events when toggle is open
 * 
 * @param Store, Object, model or store of the current instance
 * @returns Function, keyboard event handler
 * 
 * @param Event, document keydown event dispatched from document
 */
export const keyListener = Store => e => {
    switch (e.keyCode){
        case 27:
            e.preventDefault();
            startToggleLifecycle(Store);
            break;
    }
};

export const broadcast = Store => state => {
    const event = new CustomEvent(EVENTS[state.isOpen ? 'OPEN' : 'CLOSE'], {
        bubbles: true,
        detail: {
            getState: Store.getState
        }
    });
    state.node.dispatchEvent(event);
};