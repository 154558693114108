import {
    writeCookie,
    groupValueReducer,
    deleteCookies,
    getFocusableChildren,
    broadcast,
    togglePrehook,
    togglePosthook
} from './utils';
import { ACCEPTED_TRIGGERS, EVENTS } from './constants';
import { apply } from './consent';
import { updateConsent, updateBannerOpen } from './reducers';
import toggle from '../toggle';

export const initBanner = Store => () => {
    const state = Store.getState();
    if (state.bannerOpen || (state.settings.hideBannerOnFormPage && document.querySelector(`.${state.settings.classNames.formContainer}`))) return;
    document.body.firstElementChild.insertAdjacentHTML('beforebegin', state.settings.bannerTemplate(state.settings));

    [].slice.call(document.querySelectorAll('body > *:not(.privacy-banner)')).forEach(el => el.setAttribute('aria-hidden', 'true'));

    Store.update(
        updateBannerOpen,
        {
            bannerOpen: true,
            toggle:  toggle('.js-toggle-banner', { prehook: togglePrehook(Store), callback: togglePosthook(Store) })[0],
            focusableChildren: [
                ...getFocusableChildren(document.querySelector('.privacy-banner__content')),
                ...getFocusableChildren(document.querySelector('.privacy-banner__actions'))
            ]
        },
        [
            () => document.addEventListener('keydown', state.keyListener),
            broadcast(EVENTS.SHOW, Store)
        ]);
};

export const showBanner = Store => cb => {
    initBanner(Store)();
    const { bannerOpen, focusableChildren } = Store.getState();
    if (!bannerOpen) return;
    initForm(Store)();
    if (focusableChildren.length > 0) focusableChildren[0].focus();

    if (cb && cb.call) cb(Store.getState());
};

export const initButtonListeners = Store => () => {
    [].slice.call(document.querySelectorAll('.js-preferences-update')).forEach(btn => btn.addEventListener('click', e => {
        showBanner(Store)();
        initButtonListeners(Store)();
        initBannerListeners(Store)();
        const { toggle } = Store.getState();
        toggle.startToggle();
    }));
};

export const callToggle = (Store, banner) => () => {
    try {
        () => state.toggle.toggle();
    } catch (err) {
        /* No toggle */
    }
};

export const initBannerListeners = Store => () => {
    const state = Store.getState();

    if ((!state.bannerOpen && state.settings.hideBannerOnFormPage && document.querySelector(`.${state.settings.classNames.formContainer}`))){
        const embeddedFormPlaceholder = document.querySelector(`.${state.settings.classNames.formContainer}`);
        embeddedFormPlaceholder.classList.add(`${state.settings.classNames.banner}`);
    }

    const banner = document.querySelector(`.${state.settings.classNames.banner}`);

    if (!banner) return;

    const composeSelector = classSelector => ACCEPTED_TRIGGERS.map(sel => `${sel}.${classSelector}`).join(', ');

    const acceptBtns = [].slice.call(document.querySelectorAll(composeSelector(state.settings.classNames.acceptBtn)));
    const rejectButtons = [].slice.call(document.querySelectorAll(`.${state.settings.classNames.rejectBtn}`));

    if (acceptBtns.length === 0) console.warn(`Cannot find cookie banner accept buttons using selector ${composeSelector(state.settings.classNames.acceptBtn)}`);
    if (rejectButtons.length === 0) console.warn(`Cannot find cookie banner reject buttons using selector ${composeSelector(state.settings.classNames.rejectBtn)}`);

    acceptBtns.forEach(acceptBtn => {
        if (acceptBtns) {
            acceptBtn.addEventListener('click', e => {
                const isEmbeddedForm = e.currentTarget.classList.contains('privacy-banner__btn--inline-form');
                Store.update(
                    updateConsent,
                    Object.keys(state.settings.types).reduce((acc, type) => {
                        acc[type] = 1;
                        return acc;
                    }, {}),
                    [
                        writeCookie,
                        apply(Store),
                        callToggle(Store, banner),
                        removeBanner(Store, banner),
                        initForm(Store, false, isEmbeddedForm),
                        broadcast(EVENTS.CONSENT, Store),
                    ]
                );
                document.documentElement.classList.remove('on--preferences','privacy-banner-no-outline');
            });
        } else {
            console.warn('Banner accept element must be a Button or Anchor. No trigger event added.');
        }
    });

    rejectButtons.forEach(rejectBtn => {
        rejectBtn.addEventListener('click', e => {
            const isEmbeddedForm = e.currentTarget.classList.contains('privacy-banner__btn--inline-form');
            Store.update(
                updateConsent,
                Object.keys(state.settings.types).reduce((acc, type) => {
                    acc[type] = 0;
                    return acc;
                }, {}),
                [
                    writeCookie,
                    apply(Store),
                    callToggle(Store, banner),
                    removeBanner(Store, banner),
                    initForm(Store, false, isEmbeddedForm),
                    broadcast(EVENTS.CONSENT, Store),
                ]
            );
        });
        document.documentElement.classList.remove('on--preferences');
    });
};

const removeBanner = (Store, banner) => () => {
    const state = Store.getState();

    if (state.bannerOpen && state.settings.hideBannerOnFormPage){
        if (banner && banner.parentNode) {
            banner.parentNode.removeChild(banner);
            [].slice.call(document.querySelectorAll('body > *:not(.privacy-banner)')).forEach(el => el.removeAttribute('aria-hidden'));
            Store.update(updateBannerOpen, { bannerOpen: false }, [ broadcast(EVENTS.HIDE, Store) ]);
        }
    }


};

const suggestedConsent = state => Object.keys(state.consent).length > 0
    ? state
    : Object.assign({}, state, {
        consent: Object.keys(state.settings.types).reduce((acc, type) => {
            if (state.settings.types[type].suggested) acc[type] = 1;
            return acc;
        }, {})
    });

export const initForm = (Store, track = true, isEmbeddedFormRefresh = false) => () => {
    const state = Store.getState();
    const formContainer = document.querySelector(`.${state.settings.classNames.formContainer}`);

    if (!formContainer) return;

    formContainer.innerHTML = state.settings.formTemplate(suggestedConsent(state));

    const form = document.querySelector(`.${state.settings.classNames.form}`);
    const banner = document.querySelector(`.${state.settings.classNames.banner}`);
    const button = document.querySelector(`.${state.settings.classNames.submitBtn}`);
    const groups = [].slice.call(document.querySelectorAll(`.${state.settings.classNames.field}`)).reduce((groups, field) => {
        const groupName = field.getAttribute('name').replace('privacy-', '');
        if (groups[groupName]) groups[groupName].push(field);
        else groups[groupName] = [field];
        return groups;
    }, {});
    const formAnnouncement = document.querySelector(`.${state.settings.classNames.formAnnouncement}`)
                            || document.body.appendChild(Object.assign(document.createElement('div'), { className: state.settings.classNames.formAnnouncement, role: 'alert' }));


    const extractConsent = () => Object.keys(groups).reduce((acc, key) => {
        const value = groups[key].reduce(groupValueReducer, '');
        if (value) acc[key] = parseInt(value, 10);
        return acc;
    }, {});

    const enableButton = e => {
        if (Object.keys(extractConsent()).length !== Object.keys(groups).length) return;
        button.removeAttribute('disabled');
        form.removeEventListener('change', enableButton);
    };
    button.hasAttribute('disabled') && form.addEventListener('change', enableButton);

    form.addEventListener('submit', event => {
        event.preventDefault();
        Store.update(
            updateConsent,
            extractConsent(),
            [
                deleteCookies,
                writeCookie,
                apply(Store),
                removeBanner(Store, banner),
                broadcast(EVENTS.CONSENT, Store),
                renderMessage(button),
                renderAnnouncement(formAnnouncement)
            ]
        );
    });

    /* Form content is replace (above) - so re-initialize buttons */
    if(isEmbeddedFormRefresh) initBannerListeners(Store)();

    /* Change button copy on pages where the form is injected in-line */
    if ((!state.bannerOpen && state.settings.hideBannerOnFormPage && document.querySelector(`.${state.settings.classNames.formContainer}`))){
        const acceptBtn = document.querySelector(`.${state.settings.classNames.acceptBtn}`);
        const rejectBtn = document.querySelector(`.${state.settings.classNames.rejectBtn}`);

        if(acceptBtn && rejectBtn){
            acceptBtn.classList.add('privacy-banner__btn--inline-form');
            rejectBtn.classList.add('privacy-banner__btn--inline-form');
            acceptBtn.innerHTML = 'Accept all cookies';
            rejectBtn.innerHTML = 'Reject all cookies';
        }
    }

    if (window.location.hash.substring(1) === form.id) {
        window.scrollTo(0, form.getBoundingClientRect().top + window.scrollY);
    }
};

export const renderMessage = button => state => {
    button.insertAdjacentHTML('afterend', state.settings.messageTemplate(state));
    button.setAttribute('disabled', 'disabled');
    /* istanbul ignore next */
    window.setTimeout(() => {
        button.parentNode.removeChild(button.nextElementSibling);
        button.removeAttribute('disabled');
    }, 3000);
};

export const renderAnnouncement = container => state => {
    container.textContent = state.settings.savedMessage;
    /* istanbul ignore next */
    window.setTimeout(() => {
        container.textContent = '';
    }, 3000);
};