/* istanbul ignore file */
import { removeSubdomain } from './utils';
import {gtmSnippet, initIFrame, initThingLink} from './utils';

export default {
    hideBannerOnFormPage: true,
    name: '.HESCookiePreferences',
    path: '/',
    domain: window.location.hostname === 'localhost' ? '' : `.${removeSubdomain(window.location.hostname)}`,
    secure: true,
    samesite: 'strict',
    expiry: 365,
    types: {
    },
    necessary: [],
    policyURL: '#',
    cookiesURL: '#',
    classNames: {
        banner: 'privacy-banner',
        acceptBtn: 'privacy-banner__accept',
        rejectBtn: 'privacy-banner__reject',
        submitBtn: 'privacy-banner__submit',
        optionsBtn: 'privacy-banner__options',
        field: 'privacy-banner__field',
        form: 'privacy-banner__form',
        fieldset: 'privacy-banner__fieldset',
        legend: 'privacy-banner__legend',
        formContainer: 'privacy-banner__form-container',
        formMessage: 'privacy-banner__form-msg',
        formAnnouncement: 'privacy-banner__form-announcement',
        title: 'privacy-banner__form-title',
        description: 'privacy-banner__form-description'
    },
    hideBannerOnFormPage: true,
    savedMessage: 'Your settings have been saved.',
    messageTemplate(model){
        return `<div class="${model.settings.classNames.formMessage}" aria-hidden="true">${model.settings.savedMessage}</div>`;
    },
    bannerTemplate(model){
        return `<section role="dialog" aria-live="polite" aria-label="Cookie preferences" class="${model.classNames.banner}">
        <div class="privacy-banner__wrap">
            <div class="privacy-content">
                <div class="privacy-banner__grid-wrap">
                    <div class="privacy-banner__grid-col privacy-banner__inner">
                        <!--googleoff: all-->
                        <div class="privacy-banner__row privacy-banner__mbl-close--wrapper">
                            <button role="button" aria-label="close" class="privacy-banner__mbl-close--btn js-toggle__preferences"></button>
                        </div>

                        <div class="privacy-banner__content">
                            <h2 tabindex="0" class="privacy-banner__title">Manage your cookie preferences</h2>
                            <p class="privacy-banner__summary">We use cookies to ensure our website functions properly, gather data to help improve your experience on our site, and to show you personalised advertising.</p>
                            <p class="privacy-banner__summary">Find out what cookies are and how they are used on this website in our <a class="privacy-banner__link" target="_blank" rel="noopener noreferrer nofollow" href="${model.cookiesURL}">cookie policy</a>.</p>
                        </div>

                        <div class="privacy-banner__actions privacy-banner__set privacy-banner__btn-wrapper--stacked">
                            <button type="button" class="privacy-banner__btn ${model.classNames.acceptBtn}">Accept all and close</button>
                            <button type="button" class="privacy-banner__link js-toggle__preferences ${model.classNames.optionsBtn}">Select your cookie preferences</button>
                        </div>

                        <div id="preferences" class="privacy-banner__panel js-toggle-banner" data-toggle="js-toggle__preferences">
                            <p class="privacy-banner__btn-text--row">Accept or reject all cookies, or edit your choices below and click 'Save my settings'.</p>

                            <p>Find out what cookies are and how they are used on this website in our <a class="privacy-banner__link" target="_blank" rel="noopener noreferrer nofollow" href="${model.cookiesURL}">cookie policy</a>.</p>

                            <div class="${model.classNames.formContainer}"></div>
                        </div>
                        <!--googleon: all-->
                    </div>
                </div>
            </div>
        </div>
        </section>`;
    },
    formTemplate(model){
        return `<form id="preferences-form" class="privacy-banner__grid-row ${model.settings.classNames.form}" novalidate>
        <div class="privacy-banner__col privacy-banner__grid-col">
            <div class="privacy-banner__set privacy-banner__set--upper privacy-banner__btn-wrapper--stacked">
                <button type="button" class="privacy-banner__btn privacy-banner__btn--secondary ${model.settings.classNames.acceptBtn}">Accept all and close</button>
                <button type="button" class="privacy-banner__btn privacy-banner__btn--secondary ${model.settings.classNames.rejectBtn}">Reject all and close</button>
            </div>
        </div>

        ${Object.keys(model.settings.types).map(type => `<div class="privacy-banner__col privacy-banner__grid-col">
        <fieldset class="${model.settings.classNames.fieldset}">
            <legend class="${model.settings.classNames.legend}">
                <h2 class="${model.settings.classNames.title}">${model.settings.types[type].title}</h2>
                </legend>

            ${model.settings.types[type].labels ?
                `<div class="privacy-banner__row privacy-banner__pushBottom">
                    <div class="${model.settings.classNames.description}">Accepting this category enables the website to track these cookies.</div>

                    <div class="privacy-banner__row">
                    <div class="relative">
                        <label class="privacy-banner__label">
                            <input
                                class="${model.settings.classNames.field}"
                                type="radio"
                                name="privacy-${type.split(' ')[0].replace(' ', '-')}"
                                value="1"
                                ${model.consent[type] === 1 ? `checked` : ''}>
                            <span class="privacy-banner__label-text">${model.settings.types[type].labels.yes}</span>
                        </label>    
                    </div>
                </div>
                <div class="privacy-banner__row">
                    <div class="relative">
                        <label class="privacy-banner__label">
                            <input
                                class="${model.settings.classNames.field}"
                                type="radio"
                                name="privacy-${type.split(' ')[0].replace(' ', '-')}"
                                value="0"
                                ${model.consent[type] === 0 ? `checked` : ''}>
                            <span class="privacy-banner__label-text">${model.settings.types[type].labels.no}</span>
                        </label>    
                    </div>
                </div>


                </div>` : '' }

                <div class="${model.settings.classNames.description}">${model.settings.types[type].description}</div>

            ${model.settings.types[type].details ? `<div class="privacy-banner__row">
                <details>
                    <summary class="cookies-details__summary">${model.settings.types[type].title} details</summary>
                    <div tabindex="0" class="cookies-details__list">${model.settings.types[type].details.map(detail => 
                        `<div class="cookies-details__list--item">
                        <dl class="cookies-details__list--dl">
                        <dt class="cookies-details__list--dt">Name</dt><dd class="cookies-details__list--dd">${detail.name}<dd>
                        <dt class="cookies-details__list--dt">Provider</dt><dd class="cookies-details__list--dd">${detail.provider}<dd>
                        <dt class="cookies-details__list--dt">Type</dt><dd class="cookies-details__list--dd">${detail.type}<dd>
                        <dt class="cookies-details__list--dt">Duration</dt><dd class="cookies-details__list--dd">${detail.duration}<dd>
                        <dt class="cookies-details__list--dt">Purpose</dt><dd class="cookies-details__list--dd">${detail.purpose}<dd>
                        </dl></div>`
                    ).join('')}</div>
                </details>
            </div>` : ''}


        </fieldset></div>`).join('')}

        <div class="privacy-banner__grid-col">
            <p>To save your settings, please select 'on' or 'off' in all cookie preference sections and click the button below:</p>
        </div>

        <div class="privacy-banner__set">
            <button class="privacy-banner__btn ${model.settings.classNames.submitBtn}">Save my settings</button>
        </div>
    </form>`;
    }
};