import { createStore } from '../../shared/store';
import {
    findToggles,
    keyListener,
    initUI,
    startToggleLifecycle,
    toggle
} from './dom';


/* 
 * @param settings, Object, merged defaults + options passed in as instantiation config to module default
 * @param node, HTMLElement, DOM node to be toggled
 *
 * @returns Object, Toggle API
 */
export default ({ node, settings }) => {
    const Store = createStore();
    //set initial state of Store
    Store.dispatch({
        node,
        settings,
        toggles: findToggles(node),
        isOpen: false,
        classTarget: document.documentElement,
        statusClass: `on--${node.getAttribute('id')}`,
        lastFocused: false,
        keyListener: keyListener(Store),
    }, [ initUI(Store) ]);


    return {
        node,
        startToggle: startToggleLifecycle(Store),
        toggle: toggle(Store),
        getState: Store.getState
    };
};