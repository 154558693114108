/* istanbul ignore file */
/*
 * Default settings used by a Toggle instance if not otherwise overwritten with config
 *
 * @property prehook, Function, called before each toggle event begins
 * @property callback, Function, called after each toggle event completes
 */
export default {
    prehook: false,
    callback: false
};